.music-player {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #f7f7f7c0;
  color: #171b1c;
  border-radius: 10px;
  padding: 5px;
  position: fixed;
  margin-top: 10px;
  right: 20px;
  height: 150px;
  width: 350px;
}

.album-art {
  height: 130px;
  width: 130px;
  object-fit: cover;
  padding: 5px;
  border-radius: 15px;
}

.music-player-right {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-between;
  height: 100%;
  width: 220px;
  padding: 5%;
}

.song-info {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  row-gap: 3px;
  align-items: left;
  height: 70%;
}

.now-playing {
  font-size: 10px;
  opacity: 0.4;
  font-family: "NayOS", sans-serif;
  font-weight: bold;
}

.song-title {
  font-size: 16px;
  font-family: "NayOS", sans-serif;
  font-weight: bold;
}

.song-artist {
  font-size: 12px;
  opacity: 0.4;
  font-family: "NayOS", sans-serif;
  font-weight: bold;
}

.player-controls {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  column-gap: 5px;
  width: 100%;
}
.player-controls button {
  display: flex;
  flex-direction: row;
  align-items: center;

  background: #171b1c;
  border-radius: 20px;
  color: #f7f7f7;
  cursor: pointer;
  padding: 0px 14px;
  font-size: 12px;
  font-family: "NayOS", sans-serif;
  font-weight: light;
}

.player-controls img {
  height: 10px;
  padding: 0px 5px 0 0;
}

img {
  image-resolution: pixelated;
}
