:root {
  --color-primary: #171b1c;
  --color-secondary: #f7f7f7;
  --color-tertiary: #f3ccc5;
}

.top-nav {
  background-color: #f7f7f78a;
  color: var(--color-primary);
  padding: 5px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-bottom: var(--color-secondary) 2px solid;
}

.nav-title,
.nav-right {
  display: flex;
  align-items: center;
}

.nav-title img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.nav-title h1,
.nav-right {
  font-family: "NayOS";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.nav-date {
  margin-right: 10px;
}

.fullscreen-button {
  background: none;
  border: none;
  cursor: pointer;
  padding-left: 10px;
}

.fullscreen-button img {
  width: 16px; /* or the size you prefer */
  height: auto;
}
