.project-card {
  width: calc(33% - 20px);
  height: auto;
  margin-bottom: 10px;
  overflow: hidden;
  transition: transform 0.2s ease-in-out;
}

.project-card:hover {
  transform: scale(1.05);
}

.project-image {
  width: 100%;
}

.project-title {
  padding: 6px;
  text-align: center;
  font-size: 16px;
  color: #171b1c;
  font-family: "Raleway", sans-serif;
  font-size: 12px;
  font-weight: bold;
  overflow-wrap: break-word;
}
