.timeline-calendar {
  overflow: hidden;
  height: 100%;
}

.timeline-calendar img {
  width: 100%;
  object-fit: contain;
  image-rendering: unset;
}
