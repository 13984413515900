:root {
  --color-primary: #171b1c;
  --color-secondary: #f7f7f7;
  --color-tertiary: #f3ccc5;
}

body {
  margin: 0;
}

.bottom-nav {
  position: fixed;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 16px;
  overflow: hidden;
  padding: 10px 50px;
  display: flex;
  column-gap: 8%;
  align-items: center;
  justify-content: center;
  height: 70px;
  width: 70%;
  overflow: visible;
  padding-bottom: 50px;
}

.bottom-nav::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 16px;
  border: 1px solid rgba(26, 26, 26, 0.05);
  background: rgba(246, 246, 246, 0.15);
  mix-blend-mode: luminosity;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(67.95704650878906px);
  z-index: -1;
}

.nav-item {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.2s ease-in-out;
}

.nav-item:hover {
  transform: translateY(-5px);
}

.nav-item img {
  height: 60px;
  padding-bottom: 5px;
  object-fit: contain;
}

.nav-item h1 {
  font-family: "NayOS";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  text-align: center;
}
