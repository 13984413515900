.clock {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  position: relative;
  border: #f7f7f752 4px solid;
  font-size: 12px;
  color: #444;
  text-align: center;
  background-color: #f7f7f788;
}

.clock::after {
  background: #aaaaaa;
  content: "";
  width: 8%;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid #fff;
}

.hour_hand {
  position: absolute;
  width: 3px;
  height: 25px;
  background: #222;
  top: 28%;
  left: 50%;
  transform-origin: bottom;
}

.min_hand {
  position: absolute;
  width: 3px;
  height: 40px;
  background: #444;
  top: 15%;
  left: 50%;
  transform-origin: bottom;
}

.sec_hand {
  position: absolute;
  width: 1px;
  height: 50px;
  background: red;
  bottom: 50%;
  left: 50%;
  transform-origin: bottom;
}

.clock span {
  position: absolute;
}

.twelve {
  top: 2px;
  left: 46%;
}

.one {
  top: 5%;
  right: 23%;
}

.eleven {
  top: 5%;
  left: 23%;
}

.two {
  top: 23%;
  right: 7%;
}

.three {
  right: 2px;
  top: 46%;
}

.four {
  right: 7%;
  bottom: 23%;
}

.five {
  bottom: 5%;
  right: 23%;
}

.six {
  bottom: 2px;
  left: 46%;
}

.seven {
  bottom: 5%;
  left: 23%;
}

.eight {
  bottom: 23%;
  left: 7%;
}

.nine {
  left: 2px;
  top: 46%;
}

.ten {
  top: 23%;
  left: 7%;
}
