.resume-window {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #f5f5f5; /* Light grey background to match the rest of the app */
}

.resume-download-icon {
  position: sticky;
  top: 10px;
  right: 10px;
  z-index: 10;
  cursor: pointer;
}

.resume-download-icon img {
  width: 20px;
  height: 20px;
}

.resume-img {
  width: 100%;
  margin-top: -30px;
  border: none;
  image-rendering: unset;
}

.resume-img-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
