@font-face {
  font-family: "NayOS";
  src: local("NayOS"), url(../fonts/NayOS.otf) format("opentype");
}

@media screen and (max-width: 768px) {
  .desktop-only {
    display: none;
  }

  .mobile-only {
    height: 100%;
    width: 100%;
  }

  .mobile-text {
    position: relative;
    margin-top: 20%;
    font-size: 16px;
    font-family: "NayOS";
    text-align: center;
    padding: 5%;
  }
}

.lazy-img {
  filter: blur(20px);
}

@media screen and (min-width: 769px) {
  .mobile-only {
    display: none;
  }
}

img {
  image-rendering: pixelated;
}

.clock-widgets {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  margin-top: 20px;
  left: 20px;
  column-gap: 20px;
}

.clock_timezone {
  font-family: "NayOS";
  font-size: 16px;
  color: #171b1c;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 5px;
}

.App {
  overflow: hidden;
}
