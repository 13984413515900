.naytv {
  position: relative;
  overflow: hidden !important;
  height: 100%;
  font-family: "NayOS", sans-serif;
  font-size: 10px;
}

.video-container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 135%;
  height: 135%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  background: #000;
}

iframe,
.youtube-player {
  width: 100%;
  height: 100%;
  display: block;
  filter: brightness(130%);
  pointer-events: none;
}

.video-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 1);
  z-index: 2;
}

.youtube-player.hidden {
  display: none;
}

.video-loader img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.pixel-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../../../assets/icons/pixel.png") repeat;
  opacity: 0.05;
  pointer-events: none;
  z-index: 1;
}

.bottom-bar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #171b1c77;
  color: #fff;
  padding: 10px;
  position: absolute;
  bottom: 0;
  z-index: 10;
}

.bottom-bar-left,
.bottom-bar-center,
.bottom-bar-right {
  display: flex;
}

.bottom-bar-left-item,
.bottom-bar-center-item,
.bottom-bar-right-item {
  cursor: pointer;
  padding: 0 10px;
}
