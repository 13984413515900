@import url("../commonstyle.css");

.boot-page {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #171b1c;
  font-family: "NayOS";
  font-size: 16px;
  overflow-y: auto;
  white-space: pre-wrap;
  padding: 20px;
}

.boot-message {
  margin: 0;
  padding: 5px 0px;
  color: #f7f7f7;
}

.cursor {
  display: inline-block;
  width: 10px;
  height: 20px;
  background-color: #f7f7f7;
  animation: blink 1s step-start infinite;
}

.blinking-cursor {
  width: 10px;
  height: 20px;
  background-color: #f7f7f7;
  position: absolute;
  top: 20px;
  left: 25px;
  transform: translateX(-50%);
  animation: blink 1s step-end infinite;
}

@keyframes blink {
  50% {
    background-color: #171b1c;
  }
}

.boot-page::-webkit-scrollbar {
  display: none;
}
