:root {
  --color-primary: #171b1c;
  --color-secondary: #f7f7f7;
  --color-tertiary: #f3ccc5;
}

.about-me {
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  padding: 15px;
}

.about-me-image {
  width: 40%;
}

.about-me-text-intro {
  font-family: "NayOS";
  font-size: 16px;
  font-weight: 400;
  padding-bottom: 20px;
}

.about-me-text-NAY {
  width: fit-content;
  color: #9490dd;
  font-size: 30px;
  font-weight: 1000;

  padding: 3px 5px;
  line-height: 130%;
}

.about-me-text-paragraph {
  font-size: 12px;
  font-weight: 600;
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  padding-bottom: 10px;
  padding-right: 30px;
  line-height: 130%;
}
