:root {
  --color-primary: #171b1c;
  --color-secondary: #f7f7f7;
  --color-tertiary: #f3ccc5;
}

.myworks-window {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 15px;
  height: 100%;
  background-color: #ffffff;
}

.my-works-sidebar {
  background: rgba(243, 204, 197, 0.3);
  padding: 20px;
  height: 100%;
}

.my-works-sidebar-title {
  font-size: 12px;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  margin-bottom: 10px;
}

.my-works-sidebar-icon {
  width: 20px;
  height: 18px;
  margin-right: 10px;
  object-fit: contain;
}
ul {
  list-style: none;
  padding-bottom: 10px;
  margin: 0;
}

li {
  white-space: nowrap;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  margin-bottom: 10px;
  cursor: pointer;
}

.my-works-projects {
  display: flex;
  flex-wrap: wrap;
  gap: 0px 30px;
  padding: 10px;
  justify-content: top;
  align-items: top;
  margin-right: 15px;
  margin-top: 15px;
}
