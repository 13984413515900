:root {
  --color-primary: #171b1c;
  --color-secondary: #f7f7f7;
  --color-tertiary: #f3ccc5;
}

.tab-bar {
  display: flex;
  background: #5c5c5c;
  position: sticky;
  top: 0px;
  z-index: 1;
  height: 35px;
}

.tab-bar.hidden {
  background: transparent;
}

.tab-item {
  flex-grow: 1;
  flex-basis: 0;
  cursor: pointer;
  padding: 10px 15px;
  transition: background-color 0.3s ease;
  border: 1px solid var(--color-primary);
  color: var(--color-secondary);
  text-align: center;
  font-family: "Raleway", sans-serif;
  font-size: 12px;
  font-weight: 600;
}

.tab-item.active {
  background: var(--color-primary);
  color: var(--color-secondary);
}

.projects-container {
  width: 100%;
  position: relative;
  overflow: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.project-body img {
  width: 100%;
  object-fit: contain;
  image-rendering: unset;
}

.project-body {
  display: flex;
  flex-direction: column;
  padding: 0px;
  margin: 0px;
}

iframe {
  pointer-events: unset;
  transform: none;
  position: unset;
}
