.scrollbar {
  width: 50%;
  overflow: auto;
  padding: 0 10px;
}

::-webkit-scrollbar {
  width: 16px;
}

::-webkit-scrollbar-track {
  border-left: 0.5px solid #2c9dbb;
  background: rgba(101, 191, 215, 0.2);
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 3px solid transparent;
  background-clip: content-box;
  background-color: rgba(101, 191, 215, 0.6);
}

.lazy-loading-img {
  transition: filter 0.5s;
}

.lazy-loading-img.loading {
  filter: blur(10px);
}
