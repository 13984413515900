.coming-soon-container {
  background-color: rgb(255, 255, 255);
  padding: 2rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.gif-references img {
  height: 100%;
}
