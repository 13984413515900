:root {
  --color-primary: #171b1c;
  --color-secondary: #f7f7f7;
  --color-tertiary: #f3ccc5;
}

.window-common {
  position: absolute;
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease;
}

.window-title-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-primary);
  color: var(--color-secondary);
  border-radius: 10px 10px 0 0;
  padding: 15px;
  user-select: none;
}

.window-buttons {
  display: flex;
}

.window-button {
  width: 15px;
  height: 15px;

  cursor: pointer;
}

.window-button img {
  width: 100%;
}

.window-button.minimize {
  background-color: #fee00a;
}

.window-button.maximize {
  background-color: #6cda45;
}

.window-title {
  font-family: "NayOS";
  flex-grow: 1;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;
  overflow: none;
}

.window-divider {
  display: inline-block;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.window-divider-line {
  flex-grow: 1;
  height: 2px;
  background-color: var(--color-secondary);
  margin: 2px 10px;
}

.window-body {
  background-color: var(--color-secondary);
  border: 10px solid var(--color-primary);
  border-radius: 0 0 10px 10px;
  overflow: auto;
  box-sizing: border-box;
  z-index: 1;
}

.window-common.close {
  visibility: hidden;
}

.window-common.open {
  visibility: visible;
  z-index: 1;
}
